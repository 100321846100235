import "./src/styles/global.scss"
// fonts
import "fontsource-proza-libre"
import "fontsource-source-serif-pro"
import "fontsource-cantarell"

import * as React from "react"
import { PreviewStoreProvider } from "gatsby-source-prismic"

export const wrapRootElement = ({ element }) => {
  return <PreviewStoreProvider>{element}</PreviewStoreProvider>
}
